import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Hi.
    </Text>
    <p>{`Tong Quang Hoang - also known as Ryan - is currently senior software engineer, focus in .NET technologies, working on full stack .NET`}</p>
    <blockquote>
      <p parentName="blockquote">{`Great satisfaction comes from sharing with others`}</p>
    </blockquote>
    <p>{`On the road of study and work, he is trying to share what he has learned or interested, mostly related to software development`}</p>
    <p>{`Let's learning and growing together!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      